import React from 'react';
import cx from 'classnames';
import Image from '../Image';
import s from './styles.module.css';

const CallToAction = ({ src, placeholder, alt, title, action }) => (
  <div className={s.section}>
    <div className={s.background}>
      <Image
        src={src}
        placeholder={placeholder}
        width={1440}
        height={620}
        alt={alt}
        className={s.image}
      />
    </div>
    <div className={s.action}>
      <h2 className={cx('white', s.title)}>{title}</h2>
      {action}
    </div>
  </div>
);

export default CallToAction;
