import React, { lazy, Suspense } from 'react';
import cx from 'classnames';
import { useTranslation, Trans } from 'react-i18next';
import lqip from 'lqip.macro';
import { getCloudinaryUrl } from '../../utils/cloudinary';
import { useModal } from '../modal';
import Input from '../common/Input';
import Button from '../common/Button';
import Hero from '../common/Hero';
import CallToAction from '../common/CallToAction';
import ParagraphWithImage from '../common/ParagraphWithImage';
import AboutEvent from './AboutEvent';

import s from './styles.module.css';

const Form = lazy(() => import('../common/Form'));

const heroPlaceholder = lqip('../../assets/images/hero.jpg');
const callToExplorePlaceholder = lqip(
  '../../assets/images/call-to-action-group.jpg'
);
const callToRegisterPlaceholder = lqip('../../assets/images/registration.jpg');

const Logo = () => (
  <h1>
    <img
      src={getCloudinaryUrl('logo_bummrn.svg')}
      alt="Enduro Perun"
      className={s.logo}
    />
  </h1>
);

const Home = () => {
  const { t } = useTranslation();
  const { openModal } = useModal();

  return (
    <>
      <div className={s.fullScreen}>
        <Hero
          src={getCloudinaryUrl('hero_wy4tfz.jpg')}
          placeholder={heroPlaceholder}
          alt={t('heroAlt')}
          backgroundClassName={s.heroBackground}
        >
          <div className={s.content}>
            <h3 className={cx(s.date, s.large)}>{t('date')}</h3>
            <h5 className={cx(s.date, s.small)}>{t('date')}</h5>
            <Logo />
            <Button onClick={openModal} className={s.button}>
              {t('Subscribe')}
            </Button>
          </div>
        </Hero>
      </div>
      <AboutEvent />
      <CallToAction
        src={getCloudinaryUrl('bg-about-race_saalq1.jpg')}
        placeholder={callToExplorePlaceholder}
        alt={t('Two riders bombing trail above Omiš')}
      />
      <ParagraphWithImage
        src={getCloudinaryUrl('keywords-shuttle_fdn1th.png')}
        alt={t(
          'First day includes free shuttle to trails near Omiš and Dugi Rat and the fun program in the chill zone.'
        )}
        title={t('Free shuttle on first day')}
      >
        <p>
          <Trans i18nKey="freeShuttleDescription.part1">Text</Trans>
          <br />
          <br />
          <Trans i18nKey="freeShuttleDescription.part2">
            Text<i>Text</i>Text
          </Trans>
          <br />
          <br />
          <Trans i18nKey="freeShuttleDescription.part3">Text</Trans>
        </p>
      </ParagraphWithImage>
      <ParagraphWithImage
        src={getCloudinaryUrl('keywords-chill-zone_lkowge.png')}
        title={t('Chill zone with food and music')}
        alt={t(
          "There's also fun program provided in the chill zone in the center of Omiš, offering beer and food, demo bikes and mini competitions."
        )}
        reverse
      >
        <p>
          {t('chillZoneDescription.part1')}
          <br />
          <br />
          {t('chillZoneDescription.part2')}
          <br />
          <br />
          {t('chillZoneDescription.part3')}
        </p>
      </ParagraphWithImage>
      <CallToAction
        src={getCloudinaryUrl('bg-registrations_mictg8.jpg')}
        placeholder={callToRegisterPlaceholder}
        alt={t('Rider jumping on the jump line above Omiš')}
      />
      <Suspense fallback={<div />}>
        <Form
          id="contact"
          name="question-form"
          title={t('Anything on you mind?')}
          submitText={t('Send it')}
          className={s.form}
          wrapperClassName={s.formWrapper}
        >
          <Input
            required
            label={t('Lets talk, start typing')}
            name="message"
            type="text"
          />
          <Input required label={t('Who are you')} name="name" type="text" />
          <Input required label={t('Your email')} name="email" type="email" />
          <Input name="_replyto" type="hidden" />
          <Input name="_next" type="hidden" value={window.location.origin} />
          <Input name="_subject" type="hidden" value="Question" />
          <Input name="_honey" type="text" style={{ display: 'none' }} />
        </Form>
      </Suspense>
    </>
  );
};

export default Home;
