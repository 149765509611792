import React, { useState, useRef } from 'react';
import cx from 'classnames';
import ReactGA from 'react-ga';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import Burger from './Burger';
import s from './styles.module.css';

const links = [
  { to: '/', labelKey: 'Home' },
  { to: '/race', labelKey: 'Race' },
  { to: '/results', labelKey: 'Results' },
];

const Navigation = () => {
  const [isBurgerOpen, setIsBurgerOpen] = useState(false);
  const { t, i18n } = useTranslation();
  const mobileEl = useRef(null);

  const openBurger = () => {
    setIsBurgerOpen(true);
    disableBodyScroll(mobileEl.current);
  };

  const closeBurger = () => {
    setIsBurgerOpen(false);
    enableBodyScroll(mobileEl.current);
  };

  const toggleBurger = () => {
    if (!isBurgerOpen) {
      openBurger();
    } else {
      closeBurger();
    }
  };

  const changeLanguage = (lang) => {
    ReactGA.set({ language: lang });
    ReactGA.event({
      category: 'Navigation',
      action: 'Changed language',
      label: lang,
    });
    i18n.changeLanguage(lang);
  };

  return (
    <nav className={s.navigation}>
      <ul className={s.list}>
        {links.map(({ to, labelKey }) => (
          <li key={to} className={s.item}>
            <Link className="link" to={to}>
              {t(labelKey)}
            </Link>
          </li>
        ))}
        <li className={s.item}>
          <a className="link" href="#contact">
            {t('Contact')}
          </a>
        </li>
        <li className={s.item}>
          <a
            rel="noopener noreferrer"
            target="_blank"
            className="link"
            href="https://coastriders.bike"
          >
            {t('Coast Riders')}
          </a>
        </li>
      </ul>
      <ul className={s.list}>
        <li className={cx(s.item, s.lang)}>
          <button
            className={cx('link', { [s.selected]: i18n.language !== 'hr-HR' })}
            onClick={() => changeLanguage('en-EN')}
          >
            En
          </button>
        </li>
        <li className={cx(s.item, s.lang)}>
          <button
            className={cx('link', { [s.selected]: i18n.language === 'hr-HR' })}
            onClick={() => changeLanguage('hr-HR')}
          >
            Hr
          </button>
        </li>
      </ul>
      <Burger isOpen={isBurgerOpen} onClick={toggleBurger} />
      <div ref={mobileEl} className={cx(s.mobile, { [s.open]: isBurgerOpen })}>
        <ul className={s.list}>
          {links.map(({ to, labelKey }) => (
            <li key={to} className={s.item}>
              <Link onClick={closeBurger} to={to} className="link">
                {t(labelKey)}
              </Link>
            </li>
          ))}
          <li className={s.item}>
            <a onClick={closeBurger} className="link" href="#contact">
              {t('Contact')}
            </a>
          </li>
          <li className={s.item}>
            <a
              href="https://coastriders.bike"
              rel="noopener noreferrer"
              target="_blank"
              onClick={closeBurger}
              className="link"
            >
              {t('Coast Riders')}
            </a>
          </li>
        </ul>
        <ul className={cx(s.list, s.langs)}>
          <li className={cx(s.item, s.lang)}>
            <button
              className={cx('link', {
                [s.selected]: i18n.language !== 'hr-HR',
              })}
              onClick={() => {
                changeLanguage('en-EN');
                closeBurger();
              }}
            >
              En
            </button>
          </li>
          <li className={cx(s.item, s.lang)}>
            <button
              className={cx('link', {
                [s.selected]: i18n.language === 'hr-HR',
              })}
              onClick={() => {
                changeLanguage('hr-HR');
                closeBurger();
              }}
            >
              Hr
            </button>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default Navigation;
