import React from 'react';
import cx from 'classnames';
import s from './styles.module.css';

const Button = (
  {
    handleRef,
    onClick,
    children,
    type = 'submit',
    dark = false,
    form,
    className,
  },
  ref
) => (
  <button
    ref={ref}
    onClick={onClick}
    type={type}
    form={form}
    className={cx('button', { [s.dark]: dark }, className)}
  >
    {children}
  </button>
);

export default React.forwardRef(Button);
