import React from 'react';
import cx from 'classnames';
import s from './styles.module.css';

const Burger = ({ onClick, isOpen }) => (
  <button onClick={onClick} className={cx(s.burger, { [s.active]: isOpen })}>
    <span className={s.icon}>
      <span className={s.middle} />
    </span>
  </button>
);

export default Burger;
