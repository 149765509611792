import * as React from 'react';
import WebFont from 'webfontloader';

const useFonts = (fonts) => {
  React.useEffect(() => {
    WebFont.load(fonts);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export default useFonts;
