import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { SimpleImg } from 'react-simple-img';
import { getCloudinaryUrl } from '../../../utils/cloudinary';
import s from './styles.module.css';

const AboutEvent = () => {
  const { t } = useTranslation();

  return (
    <div className={s.about}>
      <SimpleImg
        src={getCloudinaryUrl('eps-white.jpg')}
        width={425}
        height={565}
        alt="Enduro Perun"
        className={s.logo}
      />
      <div>
        <h2>{t('aboutEvent.title')}</h2>
        <p className={s.content}>
          <Trans i18nKey="aboutEvent.description1">
            Text<b>Text</b>Text
          </Trans>
          <br />
          <br />
          <Trans i18nKey="aboutEvent.description2">Text</Trans>
          <br />
          <br />
          <Trans i18nKey="aboutEvent.description3">
            Text
            <br />
            Text
          </Trans>
        </p>
      </div>
    </div>
  );
};

export default AboutEvent;
