import React from 'react';
import cx from 'classnames';
import { SimpleImg } from 'react-simple-img';
import s from './styles.module.css';

const ParagraphWithImage = ({ src, alt, title, children, reverse = false }) => (
  <div className={cx(s.keywords, { [s.reverse]: reverse })}>
    <div className={s.content}>
      <h2>{title}</h2>
      <br />
      {children}
    </div>
    <SimpleImg
      src={src}
      alt={alt}
      width="100%"
      height="100%"
      className={s.img}
    />
  </div>
);

export default ParagraphWithImage;
