import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import ReactDOM from 'react-dom';
import ReactGA from 'react-ga';
import i18n from './i18n';
import './theme.css';
import App from './components/App';

ReactGA.initialize('UA-132864565-2');
ReactGA.pageview(window.location.pathname + window.location.search);
ReactGA.set({
  language: i18n.language,
  development: window.location.host.indexOf('localhost') === -1,
});

const history = createBrowserHistory();

history.listen((location) => {
  ReactGA.pageview(location.pathname); // Record a pageview for the given page
});

const AppComponent = () => (
  <Router>
    <App />
  </Router>
);

const rootElement = document.getElementById('root');

if (rootElement.hasChildNodes()) {
  ReactDOM.hydrate(<AppComponent />, rootElement);
} else {
  ReactDOM.render(<AppComponent />, rootElement);
}
