import React from 'react';
import cx from 'classnames';
import Image from '../Image';
import s from './styles.module.css';

const Hero = ({ src, backgroundClassName, placeholder, alt, children }) => (
  <div className={s.hero}>
    <Image
      src={src}
      alt={alt}
      placeholder={placeholder}
      width={1440}
      height={812}
      importance="auto"
      className={cx(s.image, backgroundClassName)}
    />
    <div className={s.content}>{children}</div>
  </div>
);

export default Hero;
