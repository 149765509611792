import React from 'react';
import { SimpleImg } from 'react-simple-img';

const getSrc = (src, dimension) => (pixelRatio) => {
  const width = dimension.width * pixelRatio;
  const height = dimension.height && dimension.height * pixelRatio;
  const parsed = src.split('upload/');
  const transform = `upload/f_auto,w_${width}`;
  if (height) {
    transform.concat(`,h_${height}`);
  }
  const host = parsed[0];
  const transformSrc = host.concat(`${transform}/`).concat(parsed[1]);
  if (pixelRatio === 1) return transformSrc;
  return `${transformSrc} ${pixelRatio}x`;
};

const getSrcSet = (src, dimension) => {
  const pixelRatios = [3, 2, 1];
  return pixelRatios.map(getSrc(src, dimension));
};

const Image = ({
  src,
  width,
  height,
  className,
  importance = 'low',
  ...props
}) => (
  <div className={className}>
    <SimpleImg
      {...props}
      src={src}
      srcSet={getSrcSet(src, { width, height })}
      width="100%"
      height="100%"
    />
  </div>
);

export default Image;
