import React from 'react';
import cx from 'classnames';
import s from './styles.module.css';

const Input = ({ field, label, className, labelClassName, ...props }) =>
  label ? (
    <label className={cx(s.label, labelClassName)}>
      {label}
      <input className={cx(s.input, className)} {...field} {...props} />
    </label>
  ) : (
    <input className={cx(s.input, className)} {...field} {...props} />
  );

export default Input;
