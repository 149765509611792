import * as React from 'react';

const ModalContext = React.createContext(false);

const ModalProvider = ({ children, value }) => (
  <ModalContext.Provider value={value}>{children}</ModalContext.Provider>
);

const useModal = () => {
  const state = React.useContext(ModalContext);
  return state;
};

export { useModal, ModalProvider };
