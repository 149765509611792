import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Switch, Route, Redirect } from 'react-router-dom';
import Helmet from 'react-helmet';
import { ANNOUNCEMENT_URL } from '../../data';
import Home from '../Home';
import { ModalProvider } from '../modal';
import Navigation from './Navigation';
import Thanks from './Thanks';
import useFonts from './useFonts';
import s from './styles.module.css';

const Race = React.lazy(() => import('../Race'));
const Results = React.lazy(() => import('../Results'));
const ShuttleReservations = React.lazy(() => import('../ShuttleReservations'));
const ShuttleReservation = React.lazy(() => import('../ShuttleReservation'));
const SubscribeModal = React.lazy(() => import('./SubscribeModal'));
const Footer = React.lazy(() => import('./Footer/'));

const PagePlaceholder = () => <div className={s.pagePlaceholder} />;

function App() {
  useFonts({
    google: {
      families: ['Roboto:regular,bold,black&display=swap'],
    },
    custom: {
      families: ['FORQUE'],
      urls: ['/fonts/forque.css'],
    },
  });
  const { t, i18n } = useTranslation();
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const openModal = () => {
    setIsModalOpen(true);
  };
  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <ModalProvider value={{ isModalOpen, openModal, closeModal }}>
      <div lang={i18n.language} className={s.app}>
        <Helmet>
          <meta charSet="utf-8" />
          <meta name="robots" content="index, follow" />
          <meta name="description" content={t('htmlDescription')} />
          <title>{t('htmlTitle')}</title>
          <link rel="canonical" href="https://enduroperun.coastriders.bike" />
        </Helmet>
        <React.Suspense fallback={<PagePlaceholder />}>
          <SubscribeModal />
        </React.Suspense>
        <Navigation />
        <Switch>
          <Route path="/" exact component={Home} />
          <Route
            path="/race"
            render={(routeProps) => (
              <React.Suspense fallback={<PagePlaceholder />}>
                <Race {...routeProps} />
              </React.Suspense>
            )}
          ></Route>
          <Route
            path="/results"
            render={(routeProps) => (
              <React.Suspense fallback={<PagePlaceholder />}>
                <Results {...routeProps} />
              </React.Suspense>
            )}
          />
          <Route
            path="/announcement"
            component={() => {
              window.location.replace(ANNOUNCEMENT_URL);
            }}
          />
          <Route
            path="/shuttle"
            render={(routeProps) => (
              <React.Suspense fallback={<PagePlaceholder />}>
                <ShuttleReservation {...routeProps} />
              </React.Suspense>
            )}
          />
          <Route
            path="/reservations"
            render={(routeProps) => (
              <React.Suspense fallback={<PagePlaceholder />}>
                <ShuttleReservations {...routeProps} />
              </React.Suspense>
            )}
          />
          <Route path="/thanks" component={Thanks} />
          <Route component={() => <Redirect to="/" />}></Route>
          <Route component={Home} />
        </Switch>
        <React.Suspense fallback={<div />}>
          <Footer />
        </React.Suspense>
      </div>
    </ModalProvider>
  );
}

export default App;
