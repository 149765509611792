import React from 'react';
import { useTranslation } from 'react-i18next';
import Helmet from 'react-helmet';
import s from './styles.module.css';

const Thanks = () => {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <Helmet>
        <meta name="robots" content="noindex, nofollow" />
        <meta name="description" content={t('htmlThanksDescription')} />
        <title>{t('htmlThanksTitle')}</title>
        <link
          rel="canonical"
          href="https://enduroperun.coastriders.bike/thanks"
        />
      </Helmet>
      <div className={s.thanks}>
        <h2>{t('Thank you!')}</h2>
        <p>{t('thanksMessage')}</p>
      </div>
    </React.Fragment>
  );
};

export default Thanks;
